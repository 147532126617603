import React, { useContext, useEffect, useState } from "react";
import { Helmet } from "react-helmet";

import { observer } from "mobx-react";
import { RootStoreContext } from "../../mobX/RootStore";

// nodejs library that concatenates classes
import classNames from "classnames";
// @mui/icons-material
import LocationOn from "@mui/icons-material/LocationOn";
import EuroSymbolIcon from "@mui/icons-material/EuroSymbol";
import TodayIcon from "@mui/icons-material/Today";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import PhoneIcon from "@mui/icons-material/Phone";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import FlagIcon from "@mui/icons-material/Flag";
// core components
import Footer from "components/Footer/Footer";
import Parallax from "components/Parallax/Parallax";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "../../components/Grid/GridItem";
// custom components
import SportappHeader from "customComponents/SportappHeader/Header";
import ClubLogoImg from "customComponents/ClubLogoImg/ClubLogoImg";
import GroupsIcon from "@mui/icons-material/Groups";

import backgroundImage from "customAssets/img/ssb_background.png";
import style from "./styles";
import {
	costPerBillingIntervalMessage,
	displayTargetGroup,
	getCategoryImageURI,
	getFullHomepageURL,
	getQueryParam,
	getSafeFullName,
	mapAgeGroupEnums,
	mapDayEnumsToFullName,
} from "../../utils/Utils";
import Button from "../../components/CustomButtons/Button";
import { IconButton, Tooltip } from "@mui/material";
import { Link } from "react-router-dom";
import MapComponent from "customComponents/Map/Map";
import { getClubURI } from "../../utils/Utils";

// eslint-disable-next-line react/prop-types
const MailTo = ({ email, subject, body, children }) => {
	return (
		<a
			href={`mailto:${email}?subject=${
				encodeURIComponent(subject) || ""
			}&body=${encodeURIComponent(body) || ""}`}
		>
			{children}
		</a>
	);
};

// eslint-disable-next-line react/prop-types
const PhoneCall = ({ number, children }) => {
	return <a href={`tel:${number}`}>{children}</a>;
};

// eslint-disable-next-line react/prop-types
const OpenInNewTab = ({ url, children }) => {
	return (
		<a target="_blank" rel="noopener noreferrer" href={url}>
			{children}
		</a>
	);
};

// eslint-disable-next-line react/prop-types
const ActionChip = ({ children, color }) => {
	return (
		<Button
			round
			style={{
				backgroundColor: color,
				paddingTop: 5,
				paddingLeft: 9,
				paddingBottom: 5,
				paddingRight: 14,
				margin: 4,
			}}
		>
			{children}
		</Button>
	);
};

ActionChip.defaultProps = {
	color: "#EEEEEE",
};

const CourseDetails = observer(() => {
	const store = useContext(RootStoreContext);
	const courseStore = store.courseStore;

	const {
		tenantStore: { data: tenant, supportEmail },
	} = store;

	// Get the value of the 'id' query parameter
	const courseId = getQueryParam("id");

	const [course, setCourse] = useState();
	const [backgroundImageURI, setBackgroundImageURI] = useState(backgroundImage);
	const [lng, setLng] = useState();
	const [lat, setLat] = useState();

	useEffect(() => {
		setLng(course?.location?.geoCoordinates?.longitude);
		setLat(course?.location?.geoCoordinates?.latitude);
	}, [course]);

	useEffect(() => {
		courseStore.fetchCourse(courseId);
	}, []);

	useEffect(() => {
		const init = async () => {
			if (!courseStore.isLoading) {
				const course = courseStore.getCourseById(courseId);
				setCourse(course);
				if (course.image) {
					setBackgroundImageURI(course.image);
				} else {
					const categoryImageURI = getCategoryImageURI(course.category);
					setBackgroundImageURI(categoryImageURI);
				}
			}
		};
		init();
	}, [courseStore.isLoading]);

	const classes = style();
	const imageClasses = classNames(
		classes.imgRaised,
		classes.imgRoundedCircle,
		classes.imgFluid,
	);

	const [hover, setHover] = useState(false);
	const sendMailToAdmin = () => {
		const body =
			`Kommentar: Beschreibung der fehlerhaften Angaben… (bitte ergänzen!)\n\n\n\n\n` +
			`Verein: ${course.club.name}\n` +
			`Kategorie: ${course.category}\n` +
			`Angebot: ${course.title}\n` +
			`Link: ${window.location.href}\n`;
		window.location.href = `
			mailto:${encodeURIComponent(supportEmail)}
			?subject=${encodeURIComponent(
				"Fehlerhafte Angaben im Angebot: " + course.title,
			)}
			&body=${encodeURIComponent(body)}
		`;
	};

	const hasEmail = course?.contact?.email && course.contact.email !== "";
	const hasPhone = course?.contact?.phone && course.contact.phone !== "";

	return (
		<div>
			{course?.title && course?.clubName && (
				<Helmet>
					<title>{`${course.title} - ${course.club.name} - Ich trainiere in ${tenant.title}`}</title>
				</Helmet>
			)}
			<SportappHeader />
			<Parallax
				image={backgroundImageURI}
				filter="dark"
				className={classes.parallax}
			/>
			{course && (
				<div className={classNames(classes.main, classes.mainRaised)}>
					<div className={classes.container}>
						<GridContainer justifyContent="center">
							<GridItem xs={12} sm={12} md={12}>
								<div className={classes.profile}>
									<div className={classes.imgWrapper}>
										<Link
											to={getClubURI(
												course.club.name,
												course.club.id,
												tenant.path,
											)}
											style={{ cursor: "pointer" }}
										>
											<ClubLogoImg
												clubLogo={course.club.logo}
												alt={course.title}
												imgClasses={imageClasses}
											/>
										</Link>
									</div>
									<div className={classes.name}>
										<Link
											to={getClubURI(
												course.club.name,
												course.club.id,
												tenant.path,
											)}
											style={{ cursor: "pointer" }}
										>
											<h3
												className={classes.title}
												style={{ marginBottom: 0, textAlign: "initial" }}
											>
												{course.title}
											</h3>
										</Link>
										<h5 style={{ textAlign: "initial", marginTop: 0 }}>
											{course.club.name}
										</h5>
									</div>
									<div
										dangerouslySetInnerHTML={{
											__html: course.descriptionAsHtml,
										}}
										style={{ textAlign: "initial", marginTop: 30 }}
									/>
									<p
										className={classes.description}
										style={{ textAlign: "initial", marginTop: 30 }}
									>
										{course.targetGroup ? (
											<>
												<GroupsIcon
													style={{
														verticalAlign: "middle",
														position: "relative",
														bottom: 2,
														marginRight: 5,
													}}
												/>
												{`${displayTargetGroup(course.targetGroup)}`}
											</>
										) : (
											""
										)}
									</p>
									<div>
										<button
											onClick={() => sendMailToAdmin()}
											onMouseEnter={() => setHover(true)}
											onMouseLeave={() => setHover(false)}
											style={{
												position: "fixed",
												right: "30px",
												button: "90px",
												zIndex: "1000",
												backgroundColor: "transparent",
												border: "none",
												cursor: "pointer",
											}}
										>
											<Tooltip title="Melden">
												<IconButton size="large">
													<FlagIcon
														style={{ color: hover ? "#f44336" : "#3C4858" }}
													/>
												</IconButton>
											</Tooltip>
										</button>
									</div>
									<GridContainer>
										{course.schedule && (
											<GridItem sm={12} md={6}>
												<GridContainer>
													<GridItem sm={1} md={1} />
													<GridItem sm={11} md={11}>
														<GridContainer style={{ textAlign: "left" }}>
															<GridItem xs={1}>
																<TodayIcon className={classes.subHeaderIcon} />
															</GridItem>
															<GridItem
																xs={10}
																className={classes.subSectionContent}
															>
																<h4
																	className={classNames(
																		classes.left,
																		classes.title,
																	)}
																>
																	Termine
																</h4>
																<p>
																	Vom{" "}
																	{new Date(
																		course.schedule.startingDate,
																	).toLocaleDateString()}{" "}
																	bis zum{" "}
																	{new Date(
																		course.schedule.finalDate,
																	).toLocaleDateString()}
																	:
																</p>
																{course.schedule.schedules.map(
																	(schedule, index) => {
																		return (
																			<div
																				key={index}
																				className={classes.listItem}
																			>
																				<p className={classes.listItemLeft}>
																					{mapDayEnumsToFullName(schedule.day)}:
																				</p>
																				<p>
																					{schedule.from} - {schedule.to}
																				</p>
																			</div>
																		);
																	},
																)}
																<p>
																	<GridItem
																		xs={12}
																		sm={8}
																		md={8}
																		lg={8}
																	></GridItem>
																</p>
															</GridItem>
														</GridContainer>
													</GridItem>
												</GridContainer>
											</GridItem>
										)}
										{course.registration && (
											<GridItem sm={12} md={6}>
												<GridContainer>
													<GridItem sm={1} md={1} />
													<GridItem sm={11} md={11}>
														<GridContainer style={{ textAlign: "left" }}>
															<GridItem xs={1}>
																<AddCircleIcon
																	className={classes.subHeaderIcon}
																/>
															</GridItem>
															<GridItem
																xs={10}
																className={classes.subSectionContent}
															>
																<h4
																	className={classNames(
																		classes.left,
																		classes.title,
																	)}
																>
																	Anmeldung
																</h4>
																{course.registration.participantLimit && (
																	<p>
																		{`Kursgröße: ${course.registration.participantLimit} Plätze`}
																	</p>
																)}
																{course.registration.closingDate &&
																	course.registration.openingDate && (
																		<p>
																			{`Anmeldefrist: Vom ${new Date(
																				course.registration.openingDate,
																			).toLocaleDateString()} bis zum ${new Date(
																				course.registration.closingDate,
																			).toLocaleDateString()}`}
																		</p>
																	)}
																{course.registration.registrationLink && (
																	<OpenInNewTab
																		url={getFullHomepageURL(
																			course.registration.registrationLink,
																		)}
																	>
																		<ActionChip>
																			<div
																				className={classes.actionChipContent}
																			>
																				<OpenInNewIcon />
																				<p>Anmelden</p>
																			</div>
																		</ActionChip>
																	</OpenInNewTab>
																)}
															</GridItem>
														</GridContainer>
													</GridItem>
												</GridContainer>
											</GridItem>
										)}
										{course.costs && (
											<GridItem sm={12} md={6}>
												<GridContainer>
													<GridItem sm={1} md={1} />
													<GridItem sm={11} md={11}>
														<GridContainer style={{ textAlign: "left" }}>
															<GridItem xs={1}>
																<EuroSymbolIcon
																	className={classes.subHeaderIcon}
																/>
															</GridItem>
															<GridItem
																xs={10}
																className={classes.subSectionContent}
															>
																<h4
																	className={classNames(
																		classes.left,
																		classes.title,
																	)}
																>
																	Kosten
																</h4>
																{course.costs.map((singleCost, index) => {
																	if (
																		!("ageGroup" in singleCost) &&
																		!("cost" in singleCost) &&
																		!("billingInterval" in singleCost)
																	)
																		return null;
																	const { ageGroup, cost, billingInterval } =
																		singleCost;
																	const costWithoutEuroSign = cost
																		? cost.replace("€", "")
																		: "0";
																	return (
																		<div
																			key={index}
																			className={classes.listItem}
																		>
																			{ageGroup && (
																				<p className={classes.listItemLeft}>
																					{mapAgeGroupEnums(ageGroup)}:
																				</p>
																			)}
																			{costWithoutEuroSign.match(
																				/^[., 0-9]+$/,
																			) ? (
																				<p>
																					{costPerBillingIntervalMessage(
																						costWithoutEuroSign,
																						billingInterval,
																					)}
																				</p>
																			) : (
																				<p>{cost}</p>
																			)}
																		</div>
																	);
																})}
																<p>{course.hints}</p>
															</GridItem>
														</GridContainer>
													</GridItem>
												</GridContainer>
											</GridItem>
										)}
										{course.contact && (
											<GridItem sm={12} md={6}>
												<GridContainer>
													<GridItem sm={1} md={1} />
													<GridItem sm={11} md={11}>
														<GridContainer style={{ textAlign: "left" }}>
															<GridItem xs={1}>
																<MailOutlineIcon
																	className={classes.subHeaderIcon}
																/>
															</GridItem>
															<GridItem
																xs={10}
																className={classes.subSectionContent}
															>
																<h4
																	className={classNames(
																		classes.left,
																		classes.title,
																	)}
																>
																	{getSafeFullName(course.contact)}
																</h4>
																{hasEmail && (
																	<p>E-Mail: {course.contact.email}</p>
																)}
																{hasPhone && (
																	<p>Telefon: {course.contact.phone}</p>
																)}
																<div className={classes.actionChipsWrapper}>
																	<MailTo
																		email={course.contact.email}
																		subject={course.title}
																		body={""}
																	>
																		<ActionChip>
																			<div
																				className={classes.actionChipContent}
																			>
																				<MailOutlineIcon />
																				<p>Anschreiben</p>
																			</div>
																		</ActionChip>
																	</MailTo>
																	{hasPhone && (
																		<PhoneCall number={course.contact.phone}>
																			<ActionChip>
																				<div
																					className={classes.actionChipContent}
																				>
																					<PhoneIcon />
																					<p>Anrufen</p>
																				</div>
																			</ActionChip>
																		</PhoneCall>
																	)}
																</div>
															</GridItem>
														</GridContainer>
													</GridItem>
												</GridContainer>
											</GridItem>
										)}

										<GridItem sm={12} md={6}>
											<GridContainer>
												<GridItem sm={1} md={1} />
												<GridItem sm={11} md={11}>
													<GridContainer
														style={{ textAlign: "right" }}
													></GridContainer>
												</GridItem>
											</GridContainer>
										</GridItem>

										{/*{console.log(course)}
										{course.location && course.location.city && <MapGeolocation adresse={course.location.city} />}*/}
										{((lng && lat) ||
											(course.location &&
												course.location.street &&
												course.location.houseNumber &&
												course.location.zipCode &&
												course.location.city)) && (
											<GridItem sm={12} md={6}>
												<GridContainer>
													<GridItem sm={1} md={1} />
													<GridItem sm={11} md={11}>
														<GridContainer style={{ textAlign: "left" }}>
															<GridItem xs={1}>
																<LocationOn className={classes.subHeaderIcon} />
															</GridItem>
															<GridItem
																xs={10}
																className={classes.subSectionContent}
															>
																<h4
																	className={classNames(
																		classes.left,
																		classes.title,
																	)}
																>
																	Adresse: {course.location.name}
																</h4>
																<p>
																	{course.location.street}{" "}
																	{course.location.houseNumber}
																</p>
																<p>
																	{course.location.zipCode}{" "}
																	{course.location.city}
																</p>
																{lng && lat && (
																	<p>
																		<b>
																			<span>Karte</span>
																		</b>

																		<MapComponent
																			lng={lng}
																			lat={lat}
																			setLng={setLng}
																			setLat={setLat}
																			fixedMarker={true}
																		/>
																	</p>
																)}
															</GridItem>
														</GridContainer>
													</GridItem>
												</GridContainer>
											</GridItem>
										)}
									</GridContainer>
								</div>
							</GridItem>
						</GridContainer>
						<div style={{ height: 20 }} />
					</div>
				</div>
			)}
			<Footer parentClasses={classes} />
		</div>
	);
});

export default CourseDetails;
