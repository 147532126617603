/**
 *
 * MyClub
 *
 */

import PropTypes from "prop-types";

import React, { useContext, useEffect, useRef, useState } from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @mui/icons-material
import AddPhotoAlternateOutlined from "@mui/icons-material/AddPhotoAlternateOutlined";
import DownloadIcon from "@mui/icons-material/CloudDownload";
import * as XLSX from "xlsx";
import AddIcon from "@mui/icons-material/Add";

// core components
import Footer from "components/Footer/Footer";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Parallax from "components/Parallax/Parallax";
import Clearfix from "components/Clearfix/Clearfix";
import CustomInput from "components/CustomInput/CustomInput";
import Table from "components/Table/Table";
import Button from "components/CustomButtons/Button";
import CustomTabs from "components/CustomTabs/CustomTabs";
import Checkbox from "@mui/material/Checkbox";
// custom components
import CustomizedSnackbar from "customComponents/CustomizedSnackbar/CustomizedSnackbar";
import FilterSelector from "customComponents/FilterSelector";
import OfferingModal from "customComponents/Modals/OfferingModal";
import DeleteDialog from "customComponents/DeleteDialog";
import ContactModal from "customComponents/Modals/ContactModal";
import LocationModal from "customComponents/Modals/LocationModal";
import SportappHeader from "customComponents/SportappHeader/Header";
import WidgetGenerator from "./components/WidgetGenerator";

import placeholder from "customAssets/img/image-placeholder.png";
import backgroundImage from "customAssets/img/ssb_background.png";

import {
	getCommonAttributes,
	getEventScheduleForDisplay,
	getImageDimensions,
	getLocationAsGeoData,
	getOfferingsById,
	getSafeFullName,
	targetGroupForDisplay,
} from "../../utils/Utils";
import BulkEditModal from "../../customComponents/Modals/BulkEditModal";
import SaveClubDialog from "./components/SaveClubDialog";
import ClubIncompleteDialog from "./components/ClubIncompleteDialog";
import { RootStoreContext } from "../../mobX/RootStore";
import { observer } from "mobx-react";
import CourseModal from "../../customComponents/Modals/CourseModal";
import EventModal from "../../customComponents/Modals/EventModal";
import EditorComponent from "../../customComponents/Editor/Editor";
import messages from "utils/messages";
import CropImageModal from "customComponents/Modals/CropImageModal";

const MAX_UPLOAD_SIZE = 200 * 1024;

const useEffectOnlyOnce = (callback, dependencies, condition) => {
	const calledOnce = React.useRef(false);

	useEffect(() => {
		if (calledOnce.current) {
			return;
		}

		if (condition(dependencies)) {
			callback(dependencies);

			calledOnce.current = true;
		}
	}, [callback, condition, dependencies]);
};

export const MyClub = observer((props) => {
	const store = useContext(RootStoreContext);

	const [offeringCategoryFilterValues, setOfferingCategoryFilterValues] =
		useState([]);
	const [offeringContactFilterValues, setOfferingContactFilterValues] =
		useState([]);
	const [offeringLocationFilterValues, setOfferingLocationFilterValues] =
		useState([]);
	const [selectedOfferingIds, setSelectedOfferingIds] = useState([]);

	const [courseCategoryFilterValues, setCourseCategoryFilterValues] = useState(
		[],
	);
	const [courseContactFilterValues, setCourseContactFilterValues] = useState(
		[],
	);
	const [courseLocationFilterValues, setCourseLocationFilterValues] = useState(
		[],
	);

	const [dialogOpen, setDialogOpen] = useState(false);
	const [cropImageModalOpen, setCropImageModalOpen] = useState(false);

	const fileInput = useRef();

	const clubId = sessionStorage.getItem("clubId");
	const [club, setClub] = useState();

	const highlightedEvents = store.eventStore.highlightedEvents;

	useEffect(() => {
		window.scrollTo(0, 0);
		document.body.scrollTop = 0;
		store.clubStore.fetchClubAsAdmin(clubId);
		store.eventStore.fetchHighlightedEvents();
	}, []);

	useEffect(() => {
		if (!store.clubStore.isLoading) {
			const club = store.clubStore.getClubById(clubId);
			setClub(club);
		}
	}, [store.clubStore.isLoading]);

	const isClubComplete = (club) => {
		return (
			club &&
			club.logo &&
			club.description &&
			club.address &&
			club.address.street &&
			club.address.city &&
			club.address.zipCode &&
			club.address.houseNumber
		);
	};

	useEffectOnlyOnce(
		(dependencies) => {
			if (!isClubComplete(dependencies[0])) setDialogOpen(true);
		},
		[club],
		(dependencies) => {
			return dependencies.length && dependencies[0];
		},
	);

	const createLocation = async (location) => {
		await store.locationStore.createLocation(
			location,
			club.id,
			club.tenantAdminGroup,
			club.clubAdminGroup,
		);
		window.location.reload();
	};

	const updateLocation = (locationData) => {
		store.locationStore.updateLocation(locationData);
	};

	const deleteLocation = async (locationId, replacement) => {
		await replaceLocation(locationId, replacement);
		await store.locationStore.deleteLocation(locationId);
	};

	const replaceLocation = async (locationId, replacement) => {
		if (club.offerings) {
			for (const offering of club.offerings) {
				if (offering.location && offering.location.id === locationId) {
					const updatedOfferingValues = {
						...offering.asJson,
						location: replacement ? replacement.asJson : null,
					};
					await updateOffering(updatedOfferingValues);
				}
			}
		}
	};

	const createContact = (contact) => {
		store.contactStore.createContact(
			contact,
			club.id,
			club.tenantAdminGroup,
			club.clubAdminGroup,
		);
	};

	const updateContact = (contactData) => {
		store.contactStore.updateContact(contactData);
	};

	const deleteContact = async (contactId, replacement) => {
		await replaceContact(contactId, replacement);
		await store.contactStore.deleteContact(contactId);
	};

	const replaceContact = async (contactId, replacement) => {
		if (club.offerings) {
			for (const offering of club.offerings) {
				if (offering.contact && offering.contact.id === contactId) {
					const updatedOfferingValues = {
						...offering.asJson,
						contact: replacement ? replacement.asJson : null,
					};
					await updateOffering(updatedOfferingValues);
				}
			}
		}
	};

	const createEvent = (eventData) => {
		const { imageFile, ...event } = eventData;
		store.eventStore.createEvent(
			event,
			club.id,
			club.tenantAdminGroup,
			club.clubAdminGroup,
			imageFile,
		);
	};

	const updateEvent = (eventData) => {
		const { imageFile, ...event } = eventData;
		store.eventStore.updateEvent(event, imageFile);
	};

	const createCourse = (course, imageFile) => {
		store.courseStore.createCourse(
			course,
			club.id,
			club.tenantAdminGroup,
			club.clubAdminGroup,
			imageFile,
		);
	};

	const updateCourse = (course, imageFile) => {
		store.courseStore.updateCourse(course, imageFile);
	};

	const deleteCourse = (courseId) => {
		store.courseStore.deleteCourse(clubId, courseId);
	};

	const deleteEvent = (eventId) => {
		store.eventStore.deleteEvent(clubId, eventId);
	};

	const createOffering = (offering, imageFile) => {
		store.offeringStore.createOffering(
			offering,
			club.id,
			club.tenantAdminGroup,
			club.clubAdminGroup,
			imageFile,
		);
	};

	const updateOffering = (offering, imageFile) => {
		store.offeringStore.updateOffering(offering, imageFile);
	};

	const offeringBulkUpdate = (offerings) => {
		store.offeringStore.offeringBulkUpdate(offerings);
	};

	const deleteOffering = (offeringId) => {
		store.offeringStore.deleteOffering(clubId, offeringId);
	};

	const handleClubSubmit = () => {
		store.clubStore.updateClub(club.id);
	};

	const handleSelectImage = async (file) => {
		if (file) {
			const fileType = file.type.split("/")[0];

			if (fileType === "image") {
				const uploadSizeLimit = MAX_UPLOAD_SIZE; // Limit file size upload to 200kB
				const url = URL.createObjectURL(file);
				const dimensions = await getImageDimensions(url);

				if (file.size > uploadSizeLimit) {
					store.UIStore.setErrorMessage(
						"Das Logo darf maximal 200kB groß sein.",
					);
				} else if (Math.abs(dimensions.width - dimensions.height) > 5) {
					store.UIStore.setErrorMessage("Das Logo muss quadratisch sein.");
				} else {
					const formData = new FormData();
					formData.append("logo", file, file.name);
					await store.clubStore.updateLogo(club.id, club.logo, file);
					setCropImageModalOpen(false);
					store.UIStore.setSuccessMessage(messages.UPDATE_SUCCESS);
					if (logoErrorMessage) {
						//resetLogoErrorMessage();
					}
				}
			}
		}
	};

	const handleChangeImageClick = () => {
		setCropImageModalOpen(true);
	};

	const handleOfferingDownloadClick = (targetType) => {
		const targetData = targetType === "course" ? club.courses : club.offerings;

		const emptyOffering = {
			contact: {
				firstName: "",
				lastName: "",
				phone: "",
				email: "",
			},
			location: {
				title: "",
				street: "",
				houseNumber: "",
				zipCode: "",
				city: "",
			},
			registration: {
				participantLimit: "",
				registrationLink: "",
				openingData: "",
				closingDate: "",
			},
		};

		const weekdays = {
			Mo: 1,
			Di: 2,
			Mi: 3,
			Do: 4,
			Fr: 5,
			Sa: 6,
			So: 7,
		};

		const sheetData = targetData.map((offering) => {
			const {
				category,
				title,
				district,
				targetGroup,
				description,
				contact,
				schedules,
				location,
				costs,
				hints,
				registration,
				schedule,
			} = {
				...emptyOffering,
				...offering,
			};

			let additionalCourseCols = {};
			if (targetType === "course") {
				additionalCourseCols = {
					Start: schedule?.startingDate,
					Ende: schedule?.finalDate,
					Trainingszeiten: schedule?.schedules
						?.sort((a, b) => weekdays[a.day] - weekdays[b.day])
						.flatMap((s) => `${s.day}: ${s.from}-${s.to}`)
						.join(" "),
					Höchstteilnehmerzahl: registration?.participantLimit,
					Anmeldelink: registration?.registrationLink,
					Anmeldebeginn: registration?.openingDate,
					Anmeldeschluss: registration?.closingDate,
				};
			}

			return {
				Sportart: category,
				Titel: title,
				"♂": targetGroup.male,
				"♀": targetGroup.female,
				"Alter ab": targetGroup.from,
				"Alter bis": targetGroup.to,
				Bezirk: district,
				Beschreibung: description,
				Trainingszeiten: schedules
					?.sort((a, b) => weekdays[a.day] - weekdays[b.day])
					.flatMap((s) => `${s.day}: ${s.from}-${s.to}`)
					.join("\n"),
				Kosten: costs
					?.flatMap(
						(c) =>
							`€ ${c.cost === null ? "0" : c.cost} pro ${c.billingInterval} für ${c.ageGroup}`,
					)
					.join(" "),
				Hinweise: hints,
				Kontakt: contact ? `${contact.firstName} ${contact.lastName}` : "",
				Telefon: contact ? contact.phone : "",
				"E-Mail": contact ? contact.email : "",
				Sportstätte: location ? location.name : "",
				Adresse: location
					? `${location.street} ${location.houseNumber}\n` +
						`${location.zipCode} ${location.city}`
					: "",
				...additionalCourseCols,
			};
		});

		const ws = XLSX.utils.json_to_sheet(sheetData);

		ws["!cols"] = [
			{ width: 15 }, // category
			{ width: 30 }, // title
			{ width: 10 }, // target Group
			{ width: 10 }, // target Group
			{ width: 10 }, // target Group
			{ width: 10 }, // target Group
			{ width: 10 }, // district
			{ width: 50 }, // description
			{ width: 50 }, // schedules
			{ width: 30 }, // costs
			{ width: 30 }, // hints
			{ width: 20 }, // contact name
			{ width: 15 }, // phone
			{ width: 30 }, // email
			{ width: 25 }, // location
			{ width: 25 }, // address
			{ width: 25 }, // start
			{ width: 25 }, // ende
			{ width: 25 }, // participantlimit
			{ width: 50 }, // registration link
			{ width: 25 }, // start
			{ width: 25 }, // ende
		];

		const wb = XLSX.utils.book_new();
		XLSX.utils.book_append_sheet(
			wb,
			ws,
			targetType === "course" ? "Vereinskurse" : "Vereinsangebote",
		);
		XLSX.writeFile(
			wb,
			`${club.name} – Export vom ${new Date().toISOString()}.xlsx`,
		);
	};

	const handleOfferingCategoryFilterSelect = (event) => {
		setOfferingCategoryFilterValues(event.target.value);
	};

	const handleOfferingContactFilterSelect = (event) => {
		setOfferingContactFilterValues(event.target.value);
	};

	const handleOfferingLocationFilterSelect = (event) => {
		setOfferingLocationFilterValues(event.target.value);
	};

	const handleCourseCategoryFilterSelect = (event) => {
		setCourseCategoryFilterValues(event.target.value);
	};

	const handleCourseContactFilterSelect = (event) => {
		setCourseContactFilterValues(event.target.value);
	};

	const handleCourseLocationFilterSelect = (event) => {
		setCourseLocationFilterValues(event.target.value);
	};

	const getFilteredCategories = (offerings) => {
		return [
			...new Set( // a set only contains unique items
				offerings
					.map((offering) => offering.category)
					.filter((category) => !!category),
			),
		];
	};

	const getFilteredContacts = (offerings) => {
		return offerings
			.map((offering) => offering.contact)
			.filter(
				(contact) =>
					contact && contact.id && (contact.firstName || contact.lastName),
			)
			.reduce((prev, curr) => {
				if (!prev.some((value) => value.id === curr.id)) {
					prev.push(curr);
				}
				return prev;
			}, []);
	};

	const getFilteredLocations = (offerings) => {
		return offerings
			.map((offering) => offering.location)
			.filter((location) => location && location.id)
			.reduce((prev, curr) => {
				if (!prev.some((value) => value.id === curr.id)) {
					prev.push(curr);
				}
				return prev;
			}, []);
	};

	const getFilteredCourses = (courses) => {
		return courses.filter((course) => {
			return (
				(courseCategoryFilterValues.length === 0 ||
					courseCategoryFilterValues.includes(course.category)) &&
				(courseContactFilterValues.length === 0 ||
					(course.contact &&
						courseContactFilterValues.includes(course.contact.id))) &&
				(courseLocationFilterValues.length === 0 ||
					(course.location &&
						courseLocationFilterValues.includes(course.location.id)))
			);
		});
	};

	const getFilteredOfferings = (offerings) => {
		return offerings.filter((offering) => {
			return (
				(offeringCategoryFilterValues.length === 0 ||
					offeringCategoryFilterValues.includes(offering.category)) &&
				(offeringContactFilterValues.length === 0 ||
					(offering.contact &&
						offeringContactFilterValues.includes(offering.contact.id))) &&
				(offeringLocationFilterValues.length === 0 ||
					(offering.location &&
						offeringLocationFilterValues.includes(offering.location.id)))
			);
		});
	};

	const toggleOfferingSelect = (id) => {
		const index = selectedOfferingIds.findIndex((el) => el === id);
		if (index === -1) {
			setSelectedOfferingIds([...selectedOfferingIds, id]);
		} else {
			const newSelectedOfferingIds = [...selectedOfferingIds];
			newSelectedOfferingIds.splice(index, 1);
			setSelectedOfferingIds(newSelectedOfferingIds);
		}
	};

	//todo remove except for classes
	const { classes, isLogoLoading, logoErrorMessage } = props;

	const imageClasses = classNames(
		classes.imgRaised,
		classes.imgFluid,
		classes.imgRoundedCircle,
	);

	return (
		<div>
			<SportappHeader />
			<Parallax
				image={backgroundImage}
				filter="dark"
				className={classes.parallax}
			/>
			<CustomizedSnackbar
				open={!!store.UIStore.successMessage}
				variant={"success"}
				message={store.UIStore.successMessage}
				onClose={store.UIStore.resetSuccessMessage}
			/>
			<CustomizedSnackbar
				open={!!store.UIStore.errorMessage}
				variant={"error"}
				message={store.UIStore.errorMessage}
				onClose={store.UIStore.resetErrorMessage}
			/>
			<ClubIncompleteDialog
				open={dialogOpen}
				onClose={() => setDialogOpen(false)}
			/>
			<CropImageModal
				maxSize={MAX_UPLOAD_SIZE}
				open={cropImageModalOpen}
				handleClose={() => setCropImageModalOpen(false)}
				handleSelectImage={handleSelectImage}
			/>
			{club && !store.clubStore.isLoading && (
				<div className={classNames(classes.main, classes.mainRaised)}>
					<div className={classes.container}>
						<GridContainer justifyContent="center">
							<GridItem xs={12} sm={12} md={10}>
								<div style={{ textAlign: "center" }}>
									<div
										className={classNames(classes.imgWrapper, classes.profile)}
									>
										<div
											className={classes.imgOverlay}
											onClick={handleChangeImageClick}
										>
											<AddPhotoAlternateOutlined
												className={classes.logoIconOverlay}
											/>
											<input
												className={classes.fileInput}
												type="file"
												accept="image/png, image/jpeg"
												onChange={handleSelectImage}
												onClick={(event) => (event.target.value = "")}
												ref={fileInput}
											/>
											<span className={classes.imgOverlayTxt}>LOGO ÄNDERN</span>
										</div>
										<img
											src={
												club.logoURI && !isLogoLoading
													? club.logoURI
													: placeholder
											}
											alt={club.name}
											className={imageClasses}
										/>
										{logoErrorMessage && (
											<div className={classes.logoErrorMessage}>
												{logoErrorMessage}
											</div>
										)}
									</div>
									<div className={classes.name}>
										<h3 className={classes.title}>{club.name}</h3>
									</div>
									<GridContainer>
										<GridItem xs={12} sm={6} md={6} lg={6}>
											<CustomInput
												labelText="Vereinsname*"
												id="name"
												inputProps={{
													defaultValue: club.name,
													onChange: (event) => club.setName(event.target.value),
												}}
												formControlProps={{
													fullWidth: true,
												}}
											/>
										</GridItem>
										<GridItem xs={12} sm={12} md={12} lg={12}>
											<EditorComponent
												labelText={"Beschreibung*"}
												id="description"
												formControlProps={{
													fullWidth: true,
												}}
												editorProps={{
													html: club.descriptionAsHtml,
													onChange: (input) => club.setDescription(input),
												}}
											/>
										</GridItem>
										<GridItem xs={12} sm={10} md={10} lg={10}>
											<CustomInput
												labelText="Straße*"
												id="address.street"
												inputProps={{
													defaultValue: club.address && club.address.street,
													onChange: async (event) => {
														club.address.setStreet(event.target.value);

														const geoData = await getLocationAsGeoData(
															club.address,
														);
														club.address.setGeoCoordinates(geoData);
													},
												}}
												formControlProps={{
													fullWidth: true,
												}}
											/>
										</GridItem>
										<GridItem xs={12} sm={2} md={2} lg={2}>
											<CustomInput
												labelText="Nummer*"
												id="address.houseNumber"
												inputProps={{
													defaultValue:
														club.address && club.address.houseNumber,
													onChange: async (event) => {
														club.address.setHouseNumber(event.target.value);

														const geoData = await getLocationAsGeoData(
															club.address,
														);
														club.address.setGeoCoordinates(geoData);
													},
												}}
												formControlProps={{
													fullWidth: true,
												}}
											/>
										</GridItem>
										<GridItem xs={12} sm={3} md={3} lg={3}>
											<CustomInput
												labelText="PLZ*"
												id="address.zipCode"
												inputProps={{
													defaultValue: club.address && club.address.zipCode,
													onChange: async (event) => {
														club.address.setZipCode(event.target.value);

														const geoData = await getLocationAsGeoData(
															club.address,
														);
														club.address.setGeoCoordinates(geoData);
													},
												}}
												formControlProps={{
													fullWidth: true,
												}}
											/>
										</GridItem>
										<GridItem xs={12} sm={4} md={4} lg={4}>
											<CustomInput
												labelText="Stadt*"
												id="address.city"
												inputProps={{
													defaultValue: club.address && club.address.city,
													onChange: async (event) => {
														club.address.setCity(event.target.value);

														const geoData = await getLocationAsGeoData(
															club.address,
														);
														club.address.setGeoCoordinates(geoData);
													},
												}}
												formControlProps={{
													fullWidth: true,
												}}
											/>
										</GridItem>
										<GridItem xs={12} sm={5} md={5} lg={5}>
											<CustomInput
												labelText="Homepage"
												id="homepage"
												inputProps={{
													defaultValue: club.homepage,
													onChange: (event) =>
														club.setHomepage(event.target.value),
												}}
												formControlProps={{
													fullWidth: true,
												}}
											/>
										</GridItem>
										<GridItem xs={12} sm={12} md={12} lg={12}>
											{isClubComplete(club) ? (
												<Button
													fullWidth
													color="primary"
													onClick={handleClubSubmit}
												>
													Änderungen speichern
												</Button>
											) : (
												<SaveClubDialog onSave={handleClubSubmit} />
											)}
										</GridItem>
									</GridContainer>
								</div>
								<div style={{ marginTop: "50px" }}>
									<CustomTabs
										headerColor="primary"
										tabs={[
											{
												tabName: <span>Vereinsangebote</span>,
												//tabName: "Vereinsangeboten",
												tabContent: (
													<div>
														<span
															style={{
																padding: "15px",
																display: "inline-block",
															}}
														>
															Regelmäßig stattfindende Trainings ohne Endtermin.
															Oft ist nur die Vereinsmitgliedschaft notwendig.
														</span>
														<GridContainer>
															<GridItem xs={12} sm={12} md={4} lg={4}>
																<FilterSelector
																	label={"Nach Sportart filtern"}
																	handleFilterSelect={
																		handleOfferingCategoryFilterSelect
																	}
																	filterValues={getFilteredCategories(
																		club.offerings,
																	)}
																	value={offeringCategoryFilterValues}
																	getDisplayValue={(value) => value}
																/>
															</GridItem>
															<GridItem xs={12} sm={12} md={4} lg={4}>
																<FilterSelector
																	label={"Nach Kontakt filtern"}
																	handleFilterSelect={
																		handleOfferingContactFilterSelect
																	}
																	filterValues={getFilteredContacts(
																		club.offerings,
																	)}
																	value={offeringContactFilterValues}
																	getDisplayValue={(value) =>
																		getSafeFullName(value)
																	}
																/>
															</GridItem>
															<GridItem xs={12} sm={12} md={4} lg={4}>
																<FilterSelector
																	label={"Nach Sportstätte filtern"}
																	handleFilterSelect={
																		handleOfferingLocationFilterSelect
																	}
																	filterValues={getFilteredLocations(
																		club.offerings,
																	)}
																	value={offeringLocationFilterValues}
																	getDisplayValue={(value) => value.name}
																/>
															</GridItem>
														</GridContainer>

														{club.offerings && (
															<div>
																<Table
																	tableHead={[
																		"Sportart",
																		"Titel",
																		"Zielgruppe",
																		<Button
																			key={"download-as-excel"}
																			fullWidth
																			color="primary"
																			size="sm"
																			onClick={handleOfferingDownloadClick}
																		>
																			<DownloadIcon /> Als Excel herunterladen
																		</Button>,
																		<BulkEditModal
																			key={"bulk-edit-button"}
																			selectedIds={selectedOfferingIds}
																			contacts={club.contacts}
																			locations={club.locations}
																			createLocation={createLocation}
																			createContact={createContact}
																			updateOffering={offeringBulkUpdate}
																			offering={{
																				...getCommonAttributes(
																					getOfferingsById(
																						selectedOfferingIds,
																						club.offerings,
																					),
																				),
																				clubID: club.id,
																			}}
																			button={(onClick) => (
																				<Button
																					disabled={
																						selectedOfferingIds.length === 0
																					}
																					color="primary"
																					fullWidth
																					onClick={() => onClick()}
																				>
																					bearbeiten
																				</Button>
																			)}
																		/>,
																	]}
																	tableData={getFilteredOfferings(
																		club.offerings,
																	)
																		.map((offering, index) => ({
																			clubId: club.id,
																			index: index,
																			...offering.asJson,
																		}))
																		.sort(offeringComparator)
																		.map((offering) => [
																			offering.category,
																			offering.title,
																			targetGroupForDisplay(offering),
																			<GridContainer key={offering.id}>
																				<OfferingModal
																					onSubmit={updateOffering}
																					offering={offering}
																					contacts={club.contacts}
																					locations={club.locations}
																					createLocation={createLocation}
																					createContact={createContact}
																					button={
																						<Button simple color="primary">
																							<i className="fas fa-edit" />
																						</Button>
																					}
																				/>
																				<DeleteDialog
																					title={"Angebot löschen"}
																					message={`Möchten Sie das Angebot ${offering.title} wirklich löschen?`}
																					onDelete={deleteOffering}
																					objectId={offering.id}
																				/>
																			</GridContainer>,
																			<Checkbox
																				key={"select-for-edit-checkbox"}
																				checked={
																					selectedOfferingIds.indexOf(
																						offering.id,
																					) !== -1
																				}
																				onClick={() =>
																					toggleOfferingSelect(offering.id)
																				}
																				color="primary"
																				classes={{
																					checked: classes.checked,
																					root: classes.checkRoot,
																				}}
																			/>,
																		])}
																	customHeadClassesForCells={[0, 4, 5]}
																/>
																<OfferingModal
																	onSubmit={createOffering}
																	createLocation={createLocation}
																	createContact={createContact}
																	contacts={club.contacts}
																	locations={club.locations}
																	button={
																		<Button color="primary" fullWidth>
																			<AddIcon /> Neues Vereinsangebot
																			hinzufügen
																		</Button>
																	}
																/>
															</div>
														)}
													</div>
												),
											},
											{
												tabName: <span>Kurse</span>,
												tabContent: (
													<div>
														<span
															style={{
																padding: "15px",
																display: "inline-block",
															}}
														>
															Zeitlich begrenzte Angebote, die oft eine
															Anmeldung erfordern. Beispiele sind Schwimmkurse,
															Feriencamps oder Angebote für Sport im Park.
														</span>
														<GridContainer>
															<GridItem xs={12} sm={12} md={4} lg={4}>
																<FilterSelector
																	label={"Nach Sportart filtern"}
																	handleFilterSelect={
																		handleCourseCategoryFilterSelect
																	}
																	filterValues={getFilteredCategories(
																		club.courses,
																	)}
																	value={courseCategoryFilterValues}
																	getDisplayValue={(value) => value}
																/>
															</GridItem>
															<GridItem xs={12} sm={12} md={4} lg={4}>
																<FilterSelector
																	label={"Nach Kontakt filtern"}
																	handleFilterSelect={
																		handleCourseContactFilterSelect
																	}
																	filterValues={getFilteredContacts(
																		club.courses,
																	)}
																	value={courseContactFilterValues}
																	getDisplayValue={(value) =>
																		getSafeFullName(value)
																	}
																/>
															</GridItem>
															<GridItem xs={12} sm={12} md={4} lg={4}>
																<FilterSelector
																	label={"Nach Sportstätte filtern"}
																	handleFilterSelect={
																		handleCourseLocationFilterSelect
																	}
																	filterValues={getFilteredLocations(
																		club.courses,
																	)}
																	value={courseLocationFilterValues}
																	getDisplayValue={(value) => value.name}
																/>
															</GridItem>
														</GridContainer>

														{club.courses && (
															<div>
																<Table
																	tableHead={[
																		"Sportart",
																		"Titel",
																		"Zielgruppe",
																		<Button
																			key={"download-as-excel"}
																			fullWidth
																			color="primary"
																			size="sm"
																			onClick={() =>
																				handleOfferingDownloadClick("course")
																			}
																		>
																			<DownloadIcon /> Als Excel herunterladen
																		</Button>,
																	]}
																	tableData={getFilteredCourses(club.courses)
																		.map((course, index) => ({
																			index: index,
																			...course.asJson,
																		}))
																		.sort(offeringComparator)
																		.map((course) => [
																			course.category,
																			course.title,
																			targetGroupForDisplay(course),
																			<GridContainer key={course.id}>
																				<CourseModal
																					onSubmit={updateCourse}
																					course={course}
																					events={[
																						...club.events,
																						...highlightedEvents.filter(
																							(event) =>
																								event?.club?.id !== club.id,
																						),
																					]}
																					contacts={club.contacts}
																					locations={club.locations}
																					createLocation={createLocation}
																					createContact={createContact}
																					button={
																						<Button simple color="primary">
																							<i className="fas fa-edit" />
																						</Button>
																					}
																				/>
																				<DeleteDialog
																					title={"Kurs löschen"}
																					message={`Möchten Sie den Kurs ${course.title} wirklich löschen?`}
																					onDelete={deleteCourse}
																					objectId={course.id}
																				/>
																			</GridContainer>,
																		])}
																	customHeadClassesForCells={[0, 4, 5]}
																/>
																<CourseModal
																	onSubmit={createCourse}
																	createLocation={createLocation}
																	createContact={createContact}
																	events={[
																		...club.events,
																		...highlightedEvents.filter(
																			(event) => event?.club?.id !== club?.id,
																		),
																	]}
																	contacts={club.contacts}
																	locations={club.locations}
																	button={
																		<Button color="primary" fullWidth>
																			<AddIcon /> Neuen Kurs hinzufügen
																		</Button>
																	}
																/>
															</div>
														)}
													</div>
												),
											},
											{
												tabName: <span>Veranstaltungen</span>,
												tabContent: (
													<div>
														<span
															style={{
																padding: "15px",
																display: "inline-block",
															}}
														>
															Events die insbesondere für
															Nicht-Vereinsmitglieder von Interesse sind.
															Beispiele sind: Tage der offenen Tür, Sommerfeste
															oder Turniere.
														</span>
														{club.events && (
															<div>
																<Table
																	tableHead={["Titel", "Datum"]}
																	tableData={club.events
																		.map((event) => event.asJson)
																		.map((event) => [
																			event.name,
																			getEventScheduleForDisplay(
																				event.schedule,
																			),
																			<GridContainer key={event.id}>
																				<EventModal
																					onSubmit={updateEvent}
																					event={event}
																					button={
																						<Button simple color="primary">
																							<i className="fas fa-edit" />
																						</Button>
																					}
																				/>
																				<DeleteDialog
																					title={"Veranstaltung löschen"}
																					message={`Möchten Sie die Veranstaltung ${event.name} wirklich löschen?`}
																					onDelete={deleteEvent}
																					objectId={event.id}
																				/>
																			</GridContainer>,
																		])}
																	customHeadClassesForCells={[0, 4, 5]}
																/>
																<EventModal
																	onSubmit={createEvent}
																	button={
																		<Button color="primary" fullWidth>
																			<AddIcon /> Neue Veranstaltung erstellen
																		</Button>
																	}
																/>
															</div>
														)}
													</div>
												),
											},
											{
												tabName: "Kontakte",
												tabContent: (
													<div>
														<Table
															tableHead={[
																"Vorname",
																"Nachname",
																"E-Mail",
																"Telefon",
															]}
															tableData={club.sortedContacts.map((contact) => [
																contact.firstName,
																contact.lastName,
																contact.email,
																contact.phone,
																<GridContainer key={contact.id}>
																	<ContactModal
																		newContact={false}
																		updateContact={updateContact}
																		contact={contact.asJson}
																		button={(onClick) => (
																			<Button
																				simple
																				color="primary"
																				onClick={() => onClick()}
																			>
																				<i className="fas fa-edit" />
																			</Button>
																		)}
																	/>
																	<DeleteDialog
																		title={"Kontakt löschen"}
																		message={`Möchten sie den Kontakt ${contact.firstName} ${contact.lastName} wirklich löschen?`}
																		onDelete={deleteContact}
																		objectId={contact.id}
																		list={club.contacts.filter(
																			(item) => item.id !== contact.id,
																		)}
																		getKey={(item) => item.id}
																		getValue={(item) => item}
																		getName={(item) =>
																			`${item.firstName} ${item.lastName}`
																		}
																	/>
																</GridContainer>,
															])}
															customHeadClassesForCells={[0, 4, 5]}
														/>
														<ContactModal
															newContact={true}
															createContact={createContact}
															button={(onClick) => (
																<Button
																	color="primary"
																	fullWidth
																	onClick={() => onClick()}
																>
																	<AddIcon /> Neuen Kontakt hinzufügen
																</Button>
															)}
														/>
													</div>
												),
											},
											{
												tabName: "Sportstätten",
												tabContent: (
													<div>
														<Table
															tableHead={["Name", "Straße", "Ort"]}
															tableData={club.sortedLocations.map(
																(location) => [
																	location.name,
																	`${location.street || ""} ${
																		location.houseNumber || ""
																	}`,
																	`${location.zipCode || ""} ${
																		location.city || ""
																	}`,
																	<GridContainer key={location.id}>
																		<LocationModal
																			onSubmit={updateLocation}
																			location={location.asJson}
																			button={(onClick) => (
																				<Button
																					simple
																					color="primary"
																					onClick={() => onClick()}
																				>
																					<i className="fas fa-edit" />
																				</Button>
																			)}
																		/>
																		<DeleteDialog
																			title={"Sportstätte löschen"}
																			message={`Möchten Sie die Sportstätte ${location.name} wirklich löschen?`}
																			onDelete={deleteLocation}
																			objectId={location.id}
																			list={club.locations.filter(
																				(item) => item.id !== location.id,
																			)}
																			getKey={(item) => item.id}
																			getValue={(item) => item}
																			getName={(item) => item.name}
																		/>
																	</GridContainer>,
																],
															)}
														/>
														<LocationModal
															onSubmit={createLocation}
															button={(onClick) => (
																<Button
																	color="primary"
																	fullWidth
																	onClick={() => onClick()}
																>
																	<AddIcon /> Neue Sportstätte hinzufügen
																</Button>
															)}
														/>
													</div>
												),
											},
											{
												tabName: "Homepage-Widgets",
												tabContent: <WidgetGenerator clubID={club.id} />,
											},
										]}
									/>
								</div>
							</GridItem>
						</GridContainer>

						<Clearfix />
					</div>
				</div>
			)}
			<Footer parentClasses={classes} />
		</div>
	);
});

MyClub.propTypes = {
	classes: PropTypes.object,
};

export default MyClub;

const offeringComparator = (offering1, offering2) => {
	if (offering1.category > offering2.category) return 1;
	if (offering1.category < offering2.category) return -1;
	const title1 = offering1.title ? offering1.title.trim() : "";
	const title2 = offering2.title ? offering2.title.trim() : "";

	if (title1 > title2) return 1;
	if (title1 < title2) return -1;
	if (!offering1.targetGroupObject || !offering2.targetGroupObject) return 0;
	const fromAgeOffering1 = offering1.targetGroupObject.from || 0;
	const fromAgeOffering2 = offering2.targetGroupObject.from || 0;

	return fromAgeOffering1 - fromAgeOffering2;
};
